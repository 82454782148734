import { template as template_1e4fbf30b48b4ef8bad1a52674d47667 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1e4fbf30b48b4ef8bad1a52674d47667(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
