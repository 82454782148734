import { template as template_89baf8fb8dc84c84b8e0212526485348 } from "@ember/template-compiler";
const FKText = template_89baf8fb8dc84c84b8e0212526485348(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
