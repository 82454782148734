import { template as template_30351be2a8c54b0a9ac420fda96ebb29 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_30351be2a8c54b0a9ac420fda96ebb29(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
