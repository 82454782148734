import { template as template_840de16c3f154211bbd91ae186414732 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_840de16c3f154211bbd91ae186414732(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
